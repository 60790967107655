import React, { useState } from 'react';
import { getChatCompletion, PlayAudio } from '../api';
import { FaPaperclip, FaTimes } from 'react-icons/fa';
import { useAuth } from '../api/AuthContext';
import pdfToText from 'react-pdftotext';
import toast from "react-hot-toast";


const ChatInput = ({ addMessage, setIsThinking, messages }) => {
    const [message, setMessage] = useState('');
    const [image, setImage] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfText, setPdfText] = useState('');
    const [audioSource, setAudioSource] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const { user } = useAuth();





    const playAudio = async (input) => {
        const CHUNK_SIZE = 1024;
        const url = `https://api.elevenlabs.io/v1/text-to-speech/${user.voice_id}/stream`;
        const headers = {
            Accept: "audio/mpeg",
            "Content-Type": "application/json",
            "xi-api-key": process.env.REACT_APP_ELEVENLABS_API_KEY || "",
        };
        const data = {
            text: input,
            model_id: "eleven_multilingual_v2",
            voice_settings: {
                stability: 0.5,
                similarity_boost: 0.5,
            },
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers,
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok.");
            }

            const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
            const newAudioSource = newAudioContext.createBufferSource();

            const audioBuffer = await response.arrayBuffer();

            newAudioContext.decodeAudioData(audioBuffer, (buffer) => {
                newAudioSource.buffer = buffer;
                newAudioSource.connect(newAudioContext.destination);
                newAudioSource.start();
                toast("Tap to Interrupt", {
                    icon: "⏸️",
                    style: {
                        borderRadius: "10px",
                        background: "#1E1E1E",
                        color: "#F9F9F9",
                        border: "0.5px solid #3B3C3F",
                        fontSize: "14px",
                    },
                    position: "top-right",
                });
                // Stop the audio stream when playback ends
                newAudioSource.onended = () => {
                    newAudioContext.close();
                    // dismiss the toast
                    toast.dismiss();
                    // setAudioPlaying(false);
                    setAudioSource(null);
                    setAudioContext(null);
                };
            });

            // setAudioPlaying(true);
            setAudioSource(newAudioSource);
            setAudioContext(newAudioContext);
        } catch (error) {
            console.error("Error:", error);
            // setAudioPlaying(false);
        }
    };
    const handleSend = async () => {
        toast.dismiss();
        if (message.trim() || image || pdfText) {
            const userMessage = {
                sender: 'user',
                content: message
                    ? [{ type: 'text', text: message }]
                    : image
                        ? [{ type: 'image_url', image_url: { url: `data:image/jpeg;base64,${image}` } }]
                        : [{ type: 'text', text: pdfText }]
            };


            if (audioSource && audioContext) {
                audioSource.stop();
                audioContext.close();
                setAudioSource(null);
                setAudioContext(null);
            }

            addMessage(userMessage);

            const apiMessages = messages.map(msg => ({
                role: msg.sender === 'user' ? 'user' : msg.sender === 'gpt' ? 'assistant' : 'system',
                content: msg.content
            }));

            apiMessages.push({
                role: 'user',
                content: message
                    ? [{ type: 'text', text: message }]
                    : image
                        ? [{ type: 'image_url', image_url: { url: `data:image/jpeg;base64,${image}` } }]
                        : [{ type: 'text', text: pdfText }]
            });

            setIsThinking(true);
            setMessage('');
            removeFile();
            const response = await getChatCompletion(apiMessages);
            playAudio(response, user.voice_id);
            setIsThinking(false);

            const gptMessage = { sender: 'gpt', content: [{ type: 'text', text: response }] };
            addMessage(gptMessage);


            setImage(null);
            setPdfFile(null);
            setPdfText('');
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result.split(',')[1]);
                setPdfFile(null);
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePdfUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setPdfFile(file);
            setImage(null);

            const reader = new FileReader();
            reader.onloadend = async () => {
                const typedArray = new Uint8Array(reader.result);
                const extractedText = await pdfToText(file);
                setPdfText(extractedText);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const removeFile = () => {
        setImage(null);
        setPdfFile(null);
    };

    return (
        <div className="sticky bottom-0 p-4 bg-white border-t ">
            <div className="flex flex-col items-center">
                {image && (
                    <div className="flex items-center mb-2">
                        <img src={`data:image/jpeg;base64,${image}`} alt="User upload" className="h-16 w-16 object-cover rounded" />
                        <button onClick={removeFile} className="ml-2 p-1 bg-black rounded-full ">
                            <FaTimes className="text-white" />
                        </button>
                    </div>
                )}
                {pdfFile && (
                    <div className="flex items-center mb-2">
                        <p className="text-white">{pdfFile.name}</p>
                        <button onClick={removeFile} className="ml-2 p-1 bg-black rounded-full \">
                            <FaTimes className="text-white" />
                        </button>
                    </div>
                )}
                <div className="flex items-center w-full">
                    <div className="relative flex-1">
                        <input
                            type="text"
                            placeholder="Type a message..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                            className="w-full p-2 pl-12 rounded bg-white text-black border-black focus:outline-none focus:ring-2 "
                        />
                        <input
                            type="file"
                            accept="image/*,application/pdf"
                            onChange={(event) => {
                                if (event.target.files[0].type === 'application/pdf') {
                                    handlePdfUpload(event);
                                } else {
                                    handleImageUpload(event);
                                }
                            }}
                            className="hidden"
                            id="file-upload"
                        />
                        <label htmlFor="file-upload" className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black  p-2 rounded-l cursor-pointer">
                            <FaPaperclip className="text-white" />
                        </label>
                    </div>
                    <button
                        onClick={handleSend}
                        className="px-4 py-2 ml-2 bg-black text-white focus:outline-none focus:ring-2 rounded-lg"
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatInput;
