import axios from 'axios';
import { useAuth } from './api/AuthContext';

const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
const baseURL = process.env.REACT_APP_OPENAI_BASE_URL || 'https://api.openai.com/v1';

const openai = axios.create({
    baseURL,
    headers: {
        Authorization: `Bearer ${apiKey}`,
    },
});

export const convertAudioToText = async (base64Audio) => {

    const audioBlob = base64ToBlob(base64Audio, 'audio/webm');
    const formData = new FormData();
    formData.append('file', audioBlob, 'input.webm');
    formData.append('model', 'whisper-1');

    try {
        const response = await openai.post('/audio/transcriptions', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data.text;
    } catch (error) {
        handleErrorResponse(error);
    }
};

// convert file to base64



export const getChatCompletion = async (messages) => {
    try {
        const response = await openai.post('/chat/completions', {
            model: 'gpt-3.5-turbo-16k',
            messages,
        });

        return response.data.choices[0].message.content;
    } catch (error) {
        handleErrorResponse(error);
    }
};

const base64ToBlob = (base64, mime) => {
    const byteChars = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteChars.length; offset += 512) {
        const slice = byteChars.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
};

const handleErrorResponse = (error) => {
    if (error.response) {
        console.error('API Response Error:', error.response.status, error.response.data);
        throw new Error(error.response.data.message || 'An error occurred during your request.');
    } else {
        console.error('API Request Error:', error.message);
        throw new Error('An error occurred during your request.');
    }
};





