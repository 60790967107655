import './App.css';
import Speech from './Components/Speech';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Text from './Components/FinalStep';
import Chat from './Components/Chat';
import Voice from './Components/Voice';
import { ImageUploader } from './Components/ImageUploader';
import Login from './Components/Login';
import Register from './Components/Register';
import { AuthProvider } from './api/AuthContext';
import { Toaster } from 'react-hot-toast';
import MainLayout from './Components/MainLayout';
import Protection from './api/Protection';
import FinalStep from './Components/FinalStep';
import ImageConverter from './Components/image';
import Chatbot from './Components/ChatInterface';
import Experiment from './Components/Experiment';


function App() {
  return (
    <BrowserRouter>
      <Toaster />
      <AuthProvider>
        <Routes>
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Login />} />

          <Route path='/finalStep' element={
            <Protection>
              <FinalStep />
            </Protection>
          } />
          <Route path='/speech' element={
            <Protection>
              <Speech />
            </Protection>
          } />
          <Route path='/text' element={
            <Protection>
              <Text />
            </Protection>
          } />
          <Route path='/chat' element={
            <Protection>
              <Chat />
            </Protection>
          } />
          <Route path='/voice' element={
            <Protection>
              <Voice />
            </Protection>
          } />
          <Route path='/ImageUploader' element={
            <Protection>
              <ImageUploader />
            </Protection>
          } />

          <Route path='/userInterface' element={
            <Protection>
              <MainLayout />
            </Protection>
          } />

          <Route path='/chatInterface' element={
            <Protection>
              <Chatbot />
            </Protection>
          } />

          <Route path='/experiment' element={
            <Protection>
              <Experiment />
            </Protection>
          } />


        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
