import React, { useEffect, useState } from 'react';
import { useAuth } from '../api/AuthContext';
import { useNavigate } from 'react-router-dom';

const ChatHeader = () => {
    const { user } = useAuth();
    const [fetchedImages, setFetchedImages] = useState([]);
    const navigate = useNavigate();





    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch('https://onetapaisolutions.com/voiceai/public/api/getSubmittedResponse?type=image', {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('tk'),
                    },
                });
                const data = await response.json();
                if (!data.error) {
                    setFetchedImages(data.data.response);
                }

            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);


    const handleButtonClick = () => {
        navigate('/finalStep');
    };
    return (
        <div className="flex items-center p-4 bg-white text-black shadow-lg">
            <img src={`https://onetapaisolutions.com/voiceai/public/storage/${fetchedImages[0]}`} alt="Profile" className="w-16 h-16 rounded-full border-2 border-white mr-4" />
            <div className="text-2xl font-bold">Meet {user.name}</div>
            <button className="ml-auto px-4 py-2 bg-black text-white rounded-lg focus:outline-none focus:ring-2 " onClick={handleButtonClick}>
                Training Dojo

            </button>
        </div>
    );
};

export default ChatHeader;
