import React from 'react';
import { FaFilePdf } from 'react-icons/fa';
import AnimatedText from './AnimatedText';
import AssistantButton from './UserInterface';
import { useAuth } from '../api/AuthContext';
import LottieAnimation from './LottieAnimation';
import animationData from '../aibot.json';

const ChatBody = ({ addMessage, messages, isThinking, setIsThinking }) => {
    const { user } = useAuth();
    return (
        <div className="flex-1 p-4 overflow-y-auto bg-white text-black relative">
            {(messages.length === 0 || messages.length === 1) && (
                <div className="absolute inset-0 flex flex-col items-center justify-center space-y-4">
                    <main className="flex flex-col items-center justify-center flex-1 p-6 md:p-24">
                        <div className="relative flex justify-center items-center">
                            <LottieAnimation lottie={animationData} />
                        </div>
                        <div className="text-center mt-4">
                            <p className="text-lg">Hi, I’m {user.name} 👋, what would you like to talk about today?</p>
                        </div>
                        <div className="text-center mt-10 max-w-3xl">
                            <p className="text-sm font-semibold mt-8">
                                Tap the button at the bottom right to start talking with {user.name}'s AI assistant.
                            </p>
                        </div>
                    </main>
                </div>
            )}
            {messages.map((message, index) => index == 0 ? <div></div> : (
                <div
                    key={index}
                    className={`mb-4 flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                >
                    <div
                        className={`p-3 rounded-lg md:max-w-max ${message.sender === 'user'
                            ? 'bg-black text-white'
                            : 'bg-black text-white relative'
                            }`}
                    >

                        <div className="prose prose-invert">

                            {message.sender === "user" ? (message.content[0].type === 'text' && message.content[0].text) : <div></div>}

                            {message.content[0].type === 'pdf' && (
                                <div className="flex items-center">
                                    <FaFilePdf className="text-red-600 h-6 w-6" />
                                    <span className="ml-2">{message.content[0].fileName}</span>
                                </div>
                            )}
                            {message.content[0].type === 'image_url' && (
                                <img src={`${message.content[0].image_url.url}`} alt="user upload" className="w-80 h-80" />
                            )}
                        </div>

                        {message.sender === 'gpt' && (
                            <AnimatedText text={message.content[0].text} />
                        )}
                    </div>
                </div>
            ))}
            {isThinking && (
                <div className="flex justify-start mb-4">
                    <div className="p-3 rounded-lg max-w-xs md:max-w-md bg-gray-700 text-white">
                        Thinking...
                    </div>
                </div>
            )}
            {/* AssistantButton at right bottom corner */}
            <div className="bottom-0 right-0 pb-20 pr-10 fixed">
                <AssistantButton addMessage={addMessage} setIsThinking={setIsThinking} />
            </div>
        </div>
    );
};

export default ChatBody;
