import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const AnimatedText = ({ text }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [isComplete, setComplete] = useState(false);

    useEffect(() => {
        if (isComplete) return;

        let currentText = '';
        let index = 0;

        const interval = setInterval(() => {
            currentText += text[index];
            setDisplayedText(currentText);
            index += 1;

            if (index >= text.length) {
                clearInterval(interval);
                setComplete(true);
            }
        }, 10);

        return () => clearInterval(interval);
    }, [text, isComplete]);

    return (
        <ReactMarkdown
            className="prose prose-invert"
            children={displayedText}
            remarkPlugins={[remarkGfm]}
        />
    );
};

export default AnimatedText;
