import React, { createContext, useState, useEffect } from 'react';
import { currentUser, login as loginUser, logout as logoutUser } from '../api/auth.js';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            try {
                const userData = await currentUser();


                if (userData.error) {
                    throw new Error(userData.error);
                }
                if (userData.data) {
                    setUser(userData.data.user);
                } else {
                    setUser(null);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setUser(null);
            } finally {
                setLoading(false);
            }
        };

        checkUser();
    }, []);

    const login = async (email, password, confirm_password) => {
        const data = await loginUser(email, password, confirm_password);
        console.log(data);
        if (!data.error) {
            setUser(data.data.user);
            return data;
        }
        throw new Error(data.message);
    };

    const logout = async () => {
        await logoutUser();
        localStorage.removeItem('tk');
        setUser(null);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
