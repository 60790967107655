import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CheckCircleIcon } from '@heroicons/react/outline';
import Side from './Sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../api/AuthContext';

const FinalStep = () => {
  const [chat, setChat] = useState(false);
  const [speech, setSpeech] = useState(false);
  const [image, setImage] = useState(false);
  const [voiceId, setVoiceId] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [incompleteModalOpen, setIncompleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  useEffect(() => {
    const getStatus = async () => {
      try {
        const response = await axios.get('https://onetapaisolutions.com/voiceai/public/api/getAllResponseStatus', {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('tk'),
            'Content-Type': 'application/json',
          },
        });

        if (!response.data.error) {
          setChat(response.data.data.isTextSubmitted);
          setSpeech(response.data.data.isVoiceSubmitted);
          setImage(response.data.data.isImageSubmitted);
          setVoiceId(response.data.data.isVoiceIdExist);
        } else {
          alert('Failed to retrieve status');
        }
      } catch (error) {
        console.error('Error retrieving status:', error);
      }
    };

    getStatus();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://onetapaisolutions.com/voiceai/public/api/submitFormResponse', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('tk'),
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        setModalIsOpen(true);
      } else {
        alert('Failed to save response');
      }
    } catch (error) {
      console.error('Error saving response:', error);
    }
  };

  const handleTalkToAI = () => {
    if (allStepsCompleted && voiceId) {
      navigate('/chatInterface');
    } else {
      setIncompleteModalOpen(true);
    }
  };

  const allStepsCompleted = chat && speech && image;

  return (
    <div className="relative bg-white min-h-screen text-black flex items-center justify-center overflow-hidden">
      <div className="absolute top-0 left-0 z-40">
        <Side />
      </div>
      <div className="flex flex-col items-center justify-center py-10 px-4 md:px-8 w-full max-w-screen-lg">
        <h1 className="text-4xl md:text-6xl font-bold mb-6 ">Training Dojo</h1>
        <div className="w-full flex justify-center items-center space-x-8 md:space-x-12 mb-8">
          <StepIndicator label="Personal Info" completed={chat} onClick={() => navigate('/chat')} />
          <StepIndicator label="Voice Data" completed={speech} onClick={() => navigate('/voice')} />
          <StepIndicator label="User Images" completed={image} onClick={() => navigate('/ImageUploader')} />
        </div>
        <div className="text-center">
          {allStepsCompleted ? (
            <p className="text-xl md:text-3xl text-black mb-6">You have completed all the stages. Now you can train your model here.</p>
          ) : (
            <p className="text-xl md:text-3xl text-black mb-6">Please complete all the steps before submitting the update.</p>
          )}
        </div>
        <div className="flex space-x-4">
          <Button className='bg-black text-white' onClick={handleSubmit} disabled={!allStepsCompleted} text="Submit Update" />
          <Button onClick={handleTalkToAI} text="Talk To AI" />
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Form Submission Successful"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white rounded-lg p-8">
          <h2 className="text-2xl font-bold mb-4">Success</h2>
          <p className="mb-4">Model Training Completed Successfully. Now you can start talking to the AI.</p>
          <button onClick={() => setModalIsOpen(false)} className="bg-black text-white py-2 px-4 rounded mt-2 cursor-pointer ">
            Close
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={incompleteModalOpen}
        onRequestClose={() => setIncompleteModalOpen(false)}
        contentLabel="Incomplete Steps"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white rounded-lg p-8">
          <h2 className="text-2xl font-bold mb-4">Incomplete Steps</h2>
          <p className="mb-4">Please complete all steps before you can talk to the AI.</p>
          <button onClick={() => setIncompleteModalOpen(false)} className="bg-black text-white py-2 px-4 rounded mt-2 cursor-pointer ">
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

const StepIndicator = ({ label, completed, onClick }) => (
  <div className="flex flex-col items-center cursor-pointer" onClick={onClick}>
    <div className="relative flex items-center justify-center mb-2">
      <div className={`h-4 w-24 md:w-48 rounded-lg ${completed ? 'bg-black' : 'bg-gray-400'}`}></div>
      <div className={`absolute bg-white rounded-full h-8 w-8 flex items-center justify-center ${completed ? 'text-green-500' : 'text-gray-400'}`}>
        <CheckCircleIcon className="h-6 w-6 text-black" />
      </div>
    </div>
    <p className="text-sm md:text-lg font-semibold">{label}</p>
  </div>
);

const Button = ({ onClick, disabled, text }) => (
  <button
    onClick={onClick}
    className={`px-6 py-3 text-lg md:text-xl bg-black text-white rounded-lg  transition-all ${disabled && 'opacity-50 cursor-not-allowed'}`}
    disabled={disabled}
  >
    {text}
  </button>
);

export default FinalStep;
