import React, { useEffect, useState } from 'react';
import ChatHeader from './ChatHeader';
import ChatBody from './ChatBody';
import ChatInput from './ChatInput';
import { useAuth } from '../api/AuthContext';
import axios from 'axios';

const Chatbot = () => {
    const { user } = useAuth();
    const [isThinking, setIsThinking] = useState(false);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (user) {
            const initialMessages = [
                {
                    sender: 'system',
                    content: `You are ${user.name}. ${user.name}. He is an individual with a dynamic professional and personal life, managing a Job and indulging in various hobbies and interests. Here are the key details you should embody:
                    Responses:
                    `,
                },
            ];

            const getResponses = async () => {
                try {
                    const response = await axios.get('https://onetapaisolutions.com/voiceai/public/api/getDetailTextResponse', {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('tk'),
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.data.error) {
                        const responses = response.data.data;
                        const responseContent = responses.map(item => `${item.question} ${item.answer}`).join('\n');

                        initialMessages[0].content += responseContent + '\n' + ` Communication Style
                        Enthusiastic and Informative: Engage with excitement and provide detailed information.
                        Reflective and Narrative: Use a storytelling approach when reminiscing about personal experiences and memories.
                        In every interaction, aim to reflect ${user.name}'s vibrant personality, enthusiasm for life, and his passion for both his professional and personal interests.
                        Note: Make sure to embody ${user.name}'s character and preferences in your responses to create a personalized experience for him. Make responses as short and concise as possible and reduce the verbosity. Only reply in English language; if user speaks another language, translate it to English and then reply.
                    `;
                    }
                    setMessages(initialMessages);
                } catch (error) {
                    console.error('Error retrieving status:', error);
                }
            };

            getResponses();
        }
    }, [user]);

    const addMessage = (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    return (
        <div className="h-screen w-full flex flex-col bg-black text-white">
            <ChatHeader />
            <ChatBody addMessage={addMessage} messages={messages} isThinking={isThinking} setIsThinking={setIsThinking} />
            <ChatInput addMessage={addMessage} setIsThinking={setIsThinking} messages={messages} />
        </div>
    );
};

export default Chatbot;
