import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../api/AuthContext";

export default function MainLayout() {
    const { user } = useAuth();
    const [fetchedImages, setFetchedImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch('https://onetapaisolutions.com/voiceai/public/api/getSubmittedResponse?type=image', {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('tk'),
                    },
                });
                const data = await response.json();
                if (!data.error) {
                    setFetchedImages(data.data.response);
                }
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-indigo-800 via-purple-700 to-indigo-800 text-white">
            <header className="absolute top-0 left-0 right-0 p-4 flex justify-between items-center">
                <Link to="/chat">
                    <img src="/logo.png" alt="Logo" className="h-12 w-auto" />
                </Link>
            </header>

            <main className="flex flex-col items-center justify-center flex-1 p-6 md:p-24">
                <div className="relative flex justify-center items-center">
                    {fetchedImages.length > 0 ? (
                        <img src={`https://onetapaisolutions.com/voiceai/public/storage/${fetchedImages[0]}`} alt="Profile" className="rounded-full w-32 h-32 mb-4" />
                    ) : (
                        <img src="/logo.png" alt="Vimal" className="rounded-full w-32 h-32 mb-4" />
                    )}
                </div>
                <div className="text-center mt-4">
                    <p className="text-lg">Hi, I’m {user.name} 👋, what would you like to talk about today?</p>
                    <p className="text-lg mt-2">Ready to chat?</p>
                    <Link to="/chatInterface">
                        <button className="mt-4 bg-white text-indigo-800 font-bold py-2 px-4 rounded-lg">Chat Now</button>
                    </Link>
                </div>
            </main>
        </div>
    );
}
