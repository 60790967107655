import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { convertAudioToText, getChatCompletion } from "../api";
import './AssistantButton.css';
import { useAuth } from "../api/AuthContext";
import axios from 'axios';
import MicRecorder from 'mic-recorder-to-mp3';

const AssistantButton = ({ addMessage, setIsThinking }) => {
  const [recorderInitialized, setRecorderInitialized] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [recording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [thinking, setThinking] = useState(false);
  const [audioSource, setAudioSource] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  const { user } = useAuth();
  let chunks = [];

  const messages = useRef([
    {
      role: "system",
      content: `You are ${user.name}. ${user.name}. He is an individual with a dynamic professional and personal life, managing a Job and indulging in various hobbies and interests. Here are the key details you should embody:
      Responses:
      `,
    },
  ]);

  useEffect(() => {
    const getResponses = async () => {
      try {
        const response = await axios.get('https://onetapaisolutions.com/voiceai/public/api/getDetailTextResponse', {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('tk'),
            'Content-Type': 'application/json',
          },
        });

        if (!response.data.error) {
          const responses = response.data.data;
          const responseContent = responses.map(item => `${item.question} ${item.answer}`).join('\n');

          messages.current[0].content += responseContent + '\n' + ` Communication Style
        Enthusiastic and Informative: Engage with excitement and provide detailed information.
        Reflective and Narrative: Use a storytelling approach when reminiscing about personal experiences and memories.
        In every interaction, aim to reflect ${(user.name)}'s vibrant personality, enthusiasm for life, and his passion for both his professional and personal interests.
        Note: Make sure to embody ${(user.name)}'s character and preferences in your responses to create a personalized experience for him make responses as short and concise as possible and reduce the verbosity only reply in english language if user speak other language translate it to english and then reply.
      `;
        }
      } catch (error) {
        console.error('Error retrieving status:', error);
      }
    };

    getResponses();
  }, [user]);

  const playAudio = async (input) => {
    const CHUNK_SIZE = 1024;
    const url = `https://api.elevenlabs.io/v1/text-to-speech/${user.voice_id}/stream`;
    const headers = {
      Accept: "audio/mpeg",
      "Content-Type": "application/json",
      "xi-api-key": process.env.REACT_APP_ELEVENLABS_API_KEY || "",
    };
    const data = {
      text: input,
      model_id: "eleven_multilingual_v2",
      voice_settings: {
        stability: 0.5,
        similarity_boost: 0.5,
      },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
      const newAudioSource = newAudioContext.createBufferSource();

      const audioBuffer = await response.arrayBuffer();

      newAudioContext.decodeAudioData(audioBuffer, (buffer) => {
        newAudioSource.buffer = buffer;
        newAudioSource.connect(newAudioContext.destination);
        newAudioSource.start();
        toast("Tap to Interrupt", {
          icon: "⏸️",
          style: {
            borderRadius: "10px",
            background: "#1E1E1E",
            color: "#F9F9F9",
            border: "0.5px solid #3B3C3F",
            fontSize: "14px",
          },
          position: "top-right",
        });
        // Stop the audio stream when playback ends
        newAudioSource.onended = () => {
          newAudioContext.close();
          // dismiss the toast
          toast.dismiss();
          setAudioPlaying(false);
          setAudioSource(null);
          setAudioContext(null);
        };
      });

      setAudioPlaying(true);
      setAudioSource(newAudioSource);
      setAudioContext(newAudioContext);
    } catch (error) {
      console.error("Error:", error);
      setAudioPlaying(false);
    }
  };

  // Wrapper to ensure audio context creation on user interaction
  const handlePlayButtonClick = async (input) => {
    setAudioPlaying(true);
    await playAudio(input);
  };

  const startRecording = () => {
    if (recorder && recorderInitialized) {
      recorder.start().then(() => {
        setRecording(true);
      }).catch((e) => {
        console.error(e);
        toast("Microphone access denied. Please allow access to the microphone and try again.", {
          duration: 5000,
          icon: "🎤",
          style: {
            borderRadius: "10px",
            background: "#1E1E1E",
            color: "#F9F9F9",
            border: "0.5px solid #3B3C3F",
            fontSize: "14px",
          },
          position: "top-right",
        });
      });
    }
  };

  const stopRecording = () => {
    if (recorder) {
      recorder.stop().getMp3().then(([buffer, blob]) => {
        setRecording(false);
        setThinking(true);
        setIsThinking(true);

        toast("Thinking", {
          duration: 5000,
          icon: "💭",
          style: {
            borderRadius: "10px",
            background: "#1E1E1E",
            color: "#F9F9F9",
            border: "0.5px solid #3B3C3F",
            fontSize: "14px",
          },
          position: "top-right",
        });

        const file = new File(buffer, 'me-at-thevoice.mp3', {
          type: blob.type,
          lastModified: Date.now()
        });

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = async function () {
          const base64Audio = (reader.result || "").split(",")[1];
          if (base64Audio) {
            try {
              const text = await convertAudioToText(base64Audio);
              messages.current.push({ role: "user", content: text });

              addMessage({ sender: "user", content: [{ type: 'text', text: text }] });
              const completion = await getChatCompletion(messages.current);
              messages.current.push({ role: "assistant", content: completion });

              addMessage({ sender: 'gpt', content: [{ type: "text", text: completion }] });
              handlePlayButtonClick(completion);
              setThinking(false); // Reset thinking state after processing
              setIsThinking(false);
            } catch (error) {
              console.error("Error processing audio:", error);
              setThinking(false); // Reset thinking state if an error occurs
              setIsThinking(false);
            }
          }
        };
      }).catch((e) => {
        console.error(e);
        toast('We could not retrieve your message', {
          duration: 5000,
          icon: "⚠️",
          style: {
            borderRadius: "10px",
            background: "#1E1E1E",
            color: "#F9F9F9",
            border: "0.5px solid #3B3C3F",
            fontSize: "14px",
          },
          position: "top-right",
        });
      });
    }
  };

  useEffect(() => {
    const initializeRecorder = async () => {
      const newRecorder = new MicRecorder({ bitRate: 128 });
      setRecorder(newRecorder);
      try {
        await newRecorder.start();
        setRecorderInitialized(true);
        newRecorder.stop(); // Stop immediately after starting to get permission
      } catch (e) {
        console.error(e);
        toast("Microphone access denied. Please allow access to the microphone and try again.", {
          duration: 5000,
          icon: "🎤",
          style: {
            borderRadius: "10px",
            background: "#1E1E1E",
            color: "#F9F9F9",
            border: "0.5px solid #3B3C3F",
            fontSize: "14px",
          },
          position: "top-right",
        });
      }
    };

    initializeRecorder();
  }, []);

  const handleButtonClick = async () => {
    if (!recorderInitialized) {
      toast("Initializing microphone access. Please try again.", {
        duration: 5000,
        icon: "🎤",
        style: {
          borderRadius: "10px",
          background: "#1E1E1E",
          color: "#F9F9F9",
          border: "0.5px solid #3B3C3F",
          fontSize: "14px",
        },
        position: "top-right",
      });
      return;
    }
    if (audioPlaying) {
      setAudioPlaying(false);
      if (audioSource && audioContext) {
        audioSource.stop();
        audioContext.close();
        setAudioSource(null);
        setAudioContext(null);
      }
    } else if (thinking) {
      toast("Please wait for me to finish.", {
        duration: 5000,
        icon: "🙌",
        style: {
          borderRadius: "10px",
          background: "#1E1E1E",
          color: "#F9F9F9",
          border: "0.5px solid #3B3C3F",
          fontSize: "14px",
        },
        position: "top-right",
      });
      return;
    } else if (!recording) {
      toast("Listening - Click again to send", {
        icon: "🟢",
        style: {
          borderRadius: "10px",
          background: "#1E1E1E",
          color: "#F9F9F9",
          border: "0.5px solid #3B3C3F",
          fontSize: "14px",
        },
        position: "top-right",
      });
      startRecording();
    } else {
      stopRecording();
    }
  };

  return (
    <div>
      <motion.div
        onClick={handleButtonClick}
        className="hover:scale-105 ease-in-out duration-500 hover:cursor-pointer text-[70px]"
      >
        <div className="rainbow-container">
          <div className={audioPlaying ? "green speaking" : "green"}></div>
          <div className={thinking ? "pink processing" : "pink"}></div>
        </div>
      </motion.div>
    </div>
  );
};

export default AssistantButton;
