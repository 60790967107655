import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { useAuth } from '../api/AuthContext';
import LottieAnimation from './LottieAnimation';
import animationData from '../login.json';
import axios from 'axios';

const Login = () => {
  const { user, login, loading } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('to');
  const [error, setError] = useState();
  const [loadingState, setLoadingState] = useState(false);

  const [inputData, setInputData] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!loading && user) {
      navigate(redirectTo || '/chat', { replace: true });
    }
  }, [user, loading, navigate, redirectTo]);

  const validateForm = () => {
    const schema = Yup.object().shape({
      email: Yup.string().email('Invalid email address.').required('Email Required.'),
      password: Yup.string().required('Password required.'),
    });

    return schema.validate(inputData, { abortEarly: false })
      .then(() => {
        setErrors({});
        return true;
      })
      .catch((err) => {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
        return false;
      });
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const sendForm = async (e) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      setLoadingState(true);
      try {
        const data = await login(inputData.email, inputData.password);
        if (!data.error) {
          localStorage.setItem("tk", data.data.token);
          const statusResponse = await getStatus(data.data.token);
          console.log(statusResponse);
          if (statusResponse.isTextSubmitted && statusResponse.isVoiceSubmitted && statusResponse.isImageSubmitted && statusResponse.isVoiceIdExist) {
            navigate('/chatInterface', { replace: true });
          } else {
            navigate(redirectTo || '/chat', { replace: true });
          }
        } else {
          setError(data.message || 'Incorrect email or password.');
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong!');
      } finally {
        setLoadingState(false);
      }
    }
  };

  const getStatus = async (token) => {
    try {
      const response = await axios.get('https://onetapaisolutions.com/voiceai/public/api/getAllResponseStatus', {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      });

      if (!response.data.error) {
        return response.data.data;
      } else {
        throw new Error('Failed to retrieve status');
      }
    } catch (error) {
      console.error('Error retrieving status:', error);
      throw error;
    }
  };

  if (loading || loadingState) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <ClipLoader color="#4A90E2" loading={loading || loadingState} size={60} />
        <p className="ml-4 text-lg text-gray-700">Processing...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-white p-4">
      <div className="flex flex-col md:flex-row w-full max-w-4xl bg-black rounded-lg shadow-md overflow-hidden">
        <div className="hidden md:flex md:w-1/2 p-8 bg-black items-center justify-center">
          <div>
            <LottieAnimation lottie={animationData} />
          </div>
        </div>
        <div className="w-full md:w-1/2 p-8 flex flex-col justify-center border-[1px] rounded-lg border-black bg-white text-black">
          <img
            src="/logo.png"
            alt="Login illustration"
            className="mb-4 h-32 w-32 mx-auto"
          />
          <p className="text-black mb-4 text-center md:text-left">
            Please sign-in to your account and start the adventure
          </p>
          <form onSubmit={sendForm} className="space-y-4">
            <div>
              <label className="block text-black">Email</label>
              <input
                type="email"
                name="email"
                value={inputData.email}
                onChange={handleData}
                className="mt-1 block w-full px-4 py-2 border border-gray-600 rounded-md shadow-sm bg-white text-black focus:outline-none focus:ring focus:ring-opacity-50"
              />
              {errors.email && <div className="text-red-500">{errors.email}</div>}
            </div>
            <div>
              <label className="block text-black">Password</label>
              <input
                type="password"
                name="password"
                value={inputData.password}
                onChange={handleData}
                className="mt-1 block w-full px-4 py-2 border border-gray-600 rounded-md shadow-sm bg-white text-black focus:outline-none focus:ring focus:ring-opacity-50"
              />
              {errors.password && <div className="text-red-500">{errors.password}</div>}
            </div>
            <div className="flex items-center justify-between">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-black"
                />
                <span className="ml-2 text-black">Remember me</span>
              </label>
            </div>
            {error && <div className="text-red-500">{error}</div>}
            <button
              type="submit"
              className="w-full bg-black text-white  py-2 rounded-md shadow-md focus:outline-none focus:ring "
            >
              Log In
            </button>
          </form>
          <div className="mt-6 text-center">
            <p className="text-gray-900">
              New on our platform?{' '}
              <a href="/register" className="text-black hover:underline">
                Create an account
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
