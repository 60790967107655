import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Side from './Sidebar';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

const Chat = () => {
  const [count, setCount] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [inputData, setInputData] = useState('');
  const [responses, setResponses] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [textArea, setTextArea] = useState([]);
  const [allQuestionsVisible, setAllQuestionsVisible] = useState(false);

  const fetchData = async () => {
    const data = await fetch('https://onetapaisolutions.com/voiceai/public/api/question-fetch');
    const json = await data.json();
    if (!json.error) {
      setQuestions(json.data);
      setTextArea(json.data.map(() => false)); // Initialize the textArea state with false values
    }
  };

  const fetchAllAnswers = async () => {
    const data = await fetch('https://onetapaisolutions.com/voiceai/public/api/getSubmittedResponse?type=text', {
      headers: {
        'Authorization': 'Bearer ' + (localStorage.getItem('tk')),
      },
    });
    const json = await data.json();
    if (!json.error) {
      setResponses(json.data.response);
      if (json.data.response.length === questions.length) {
        setAllQuestionsVisible(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
    fetchAllAnswers();
  }, []);

  useEffect(() => {
    if (questions.length && responses.length) {
      const currentQuestionId = questions[count].id;
      const currentAnswer = responses.find(response => response.question_id === currentQuestionId);
      setInputData(currentAnswer ? currentAnswer.answer : '');
    }
  }, [count, questions, responses]);

  useEffect(() => {
    if (responses.length === questions.length) {
      setAllQuestionsVisible(true);
    }
  }, [responses, questions]);

  const handleChange = (e, index) => {
    const updatedResponses = [...responses];
    updatedResponses[index] = {
      question_id: questions[index].id,
      answer: e.target.value,
    };
    setResponses(updatedResponses);
    setInputData(e.target.value);
  };

  const handleSaveClick = (index) => {
    setTextArea((prevTextArea) =>
      prevTextArea.map((textArea, i) => (i === index ? false : textArea))
    );
  };

  const handleRemoveClick = (index) => {
    const updatedResponses = [...responses];
    updatedResponses[index] = {
      question_id: questions[index].id,
      answer: '',
    };
    setResponses(updatedResponses);
    setInputData('');
    setTextArea((prevTextArea) =>
      prevTextArea.map((textArea, i) => (i === index ? false : textArea))
    );
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://onetapaisolutions.com/voiceai/public/api/submitTextResponse', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('tk'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          response: responses,
        }),
      });

      if (response.ok) {
        setModalIsOpen(true);
      } else {
        alert('Failed to submit responses');
      }
    } catch (error) {
      console.error('Error saving response:', error);
    }
  };

  const toggleTextArea = (index) => {
    setTextArea((prevTextArea) =>
      prevTextArea.map((textArea, i) => (i === index ? !textArea : textArea))
    );
  };

  return (
    <div className="bg-white w-screen h-full flex flex-row md:flex-row">
      <div className="fixed"><Side /></div>
      <div className="min-w-screen h-4/6 ml-8 mt-20 md:w-8/12 md:h-5/6 md:ml-80 md:mt-10 flex justify-center items-center rounded-lg bg-gradient-to-r  p-4 md:p-0">
        <div className="w-full md:w-11/12 h-full md:h-5/6 rounded-xl shadow-xl  text-black p-4 md:p-8">
          <h1 className='ml-20 md:ml-36 text-2xl md:text-5xl font-bold mb-12'>Let Us Know You</h1>
          {allQuestionsVisible ? (
            questions.map((question, index) => (
              <div key={index} className="pt-4 ml-2 md:ml-6 cursor-pointer" >
                <p className="text-black text-lg md:text-xl mb-4 font-bold flex " onClick={() => toggleTextArea(index)}>
                  {question.text}
                  <div
                    className='h-7 w-7 ml-10 rounded-lg text-gray-800  '

                  >
                    {textArea[index] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </div>
                </p>
                {textArea[index] && (
                  <div>
                    <textarea
                      className="h-32 md:h-48 w-full mt-4 border p-2 bg-white text-black border-black rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
                      required
                      value={responses[index]?.answer || ''}
                      onChange={(e) => handleChange(e, index)}
                      placeholder="Type Here"
                      style={{ lineHeight: '1.5', paddingTop: '12px', display: 'block', boxSizing: 'border-box' }}
                    />
                    <div className="flex space-x-4 mt-2">
                      <button
                        className="px-4 py-2 rounded-lg text-white bg-black  focus:outline-none focus:ring "
                        onClick={() => handleSaveClick(index)}
                      >
                        Save
                      </button>
                      {/* <button
                        className="px-4 py-2 rounded-lg text-white bg-black  focus:outline-none focus:ring "
                        onClick={() => handleRemoveClick(index)}
                      >
                        Remove
                      </button> */}
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div>
              <p className="text-yellow-500 text-lg md:text-xl mb-4 font-bold flex">
                {questions[count] ? questions[count].text : 'Loading...'}
                <div
                  className='h-7 w-7 rounded-lg text-gray-200 cursor-pointer'
                  onClick={() => toggleTextArea(count)}
                >
                  {textArea[count] ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </div>
              </p>
              {textArea[count] && (
                <div>
                  <textarea
                    className="h-32 md:h-48 w-full mt-4 border p-2 bg-gray-800 text-gray-300 border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-opacity-50"
                    required
                    value={inputData}
                    onChange={(e) => handleChange(e, count)}
                    placeholder="Type Here"
                    style={{ lineHeight: '1.5', paddingTop: '12px', display: 'block', boxSizing: 'border-box' }}
                  />
                  <div className="flex space-x-4 mt-2">
                    <button
                      className="px-4 py-2 rounded-lg text-black bg-white focus:outline-none focus:ring "
                      onClick={() => handleSaveClick(count)}
                    >
                      Save
                    </button>
                    <button
                      className="px-4 py-2 rounded-lg text-black bg-white focus:outline-none focus:ring "
                      onClick={() => handleRemoveClick(count)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          {allQuestionsVisible && (
            <div className="flex justify-center mt-6">
              <button
                className="px-40 py-3 rounded-lg text-white bg-black focus:outline-none focus:ring focus:ring-indigo-300"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          )}

        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Success"
        className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-50"
        overlayClassName="fixed inset-0 bg-white bg-opacity-50"
      >
        <div className="bg-white rounded-lg p-8">
          <h2 className="text-2xl font-bold mb-4">Success</h2>
          <p className="mb-4">Your responses have been submitted successfully!</p>
          <button onClick={() => setModalIsOpen(false)} className="bg-black text-white py-2 px-4 rounded mt-2 cursor-pointer ">
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Chat;