import { CloudUploadIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import Side from './Sidebar';
import img1 from '../Components/Images/img1.jpeg';
import Modal from 'react-modal';

export function ImageUploader() {
  const numberOfCards = 4; // Number of cards
  const [images, setImages] = useState(Array(numberOfCards).fill([])); // State to manage images for each card
  const [clicked, setClicked] = useState(Array(numberOfCards).fill(false)); // State to manage clicked status for each card
  const maxNumber = 69;
  const [fetchedImages, setFetchedImages] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://onetapaisolutions.com/voiceai/public/api/getSubmittedResponse?type=image', {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('tk'),
          },
        });
        const data = await response.json();
        if (!data.error) {
          if (data.data.response.length === 0) {
            setIsPopup(true);
          }
          setFetchedImages(data.data.response);
        }

      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  const onChange = (cardIndex, imageList, addUpdateIndex) => {
    setImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[cardIndex] = imageList;
      return newImages;
    });
  };

  const handleClick = (index) => {
    setClicked((prevClicked) => {
      const newClicked = [...prevClicked];
      newClicked[index] = !newClicked[index];
      return newClicked;
    });
  };

  const handleButtonClick = (onImageUpload, index) => {
    onImageUpload();
    handleClick(index);
  };

  const removeButton = (onImageRemove, cardIndex, imageIndex) => {
    onImageRemove(imageIndex);
    handleClick(cardIndex);
  };

  const handleSubmitAllImages = async () => {
    setLoading(true);
    const formData = new FormData();
    images.forEach((imageList) => {
      imageList.forEach((image) => {
        formData.append('image[]', image.file);
      });
    });
    formData.append('type', 'image');

    try {
      const response = await fetch('https://onetapaisolutions.com/voiceai/public/api/submitImageResponse', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('tk'),
        },
        body: formData,
      });

      const data = await response.json();
      console.log('Success:', data);
      setModalIsOpen(true);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white w-screen h-screen flex flex-col items-center justify-center overflow-x-hidden p-4">
      {isPopup ? (
        <div className='bg-black h-full flex items-center justify-center'>
          <div className='bg-white w-full max-w-lg h-auto shadow-2xl relative'>
            <img alt='new' className='w-full object-cover rounded-t-lg' src={img1} />
            <button
              onClick={() => setIsPopup(false)}
              className='text-white bg-black w-full'
            >
              Continue
            </button>
          </div>
        </div>
      ) : (
        <div className='bg-white  flex flex-col w-full h-full overflow-x-hidden'>
          <div className='absolute top-0 left-0 z-40'>
            <Side />
          </div>
          <div className='flex flex-col w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto mt-16 md:mt-4 px-4 md:px-8'>
            <h1 className='text-black text-2xl md:text-4xl font-bold text-center mb-4'>Image Upload</h1>
            <p className='text-gray-900 text-center mb-8'>
              Upload your images for our AI to analyze and improve our services.
            </p>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
              {Array.from({ length: numberOfCards }, (_, cardIndex) => (
                <div key={cardIndex} className="bg-black w-full p-4 rounded-lg shadow-lg transition-all flex flex-col justify-between items-center">
                  <ImageUploading
                    multiple
                    value={images[cardIndex]}
                    onChange={(imageList, addUpdateIndex) => onChange(cardIndex, imageList, addUpdateIndex)}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      <>
                        {fetchedImages[cardIndex] && (
                          <div className="image-item h-full flex flex-col items-center justify-between">
                            <img src={`https://onetapaisolutions.com/voiceai/public/storage/${fetchedImages[cardIndex]}`} alt="" className='h-48 w-full rounded-lg object-cover mb-2' />
                            <div className="image-item__btn-wrapper space-x-2 flex justify-center mt-2">
                              <button
                                className='px-2 py-1 text-sm rounded-lg bg-white text-black '
                                onClick={() => onImageUpdate(0)}
                              >
                                Update
                              </button>
                              <button
                                className='px-2 py-1 text-sm rounded-lg bg-white text-black'
                                onClick={() => removeButton(onImageRemove, cardIndex, 0)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        )}
                        {imageList.length === 0 && !fetchedImages[cardIndex] && (
                          <div onClick={() => handleButtonClick(onImageUpload, cardIndex)} className='flex flex-col items-center justify-center h-full'>
                            <CloudUploadIcon className='h-16 w-16 md:h-20 md:w-20 lg:h-24 lg:w-24 cursor-pointer text-white' />
                            <p className='text-white mt-2'>Click to upload</p>
                          </div>
                        )}
                        {imageList.length > 0 && (
                          <>
                            {imageList.map((image, index) => (
                              <div key={index} className="image-item h-full flex flex-col items-center justify-between">
                                <img src={image['data_url']} alt="" className='h-48 w-full rounded-lg object-cover mb-2' />
                                <div className="image-item__btn-wrapper space-x-2 flex justify-center mt-2">
                                  <button
                                    className='px-2 py-1 text-sm rounded-lg bg-white text-black '
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    Update
                                  </button>
                                  <button
                                    className='px-2 py-1 text-sm rounded-lg bg-white text-black'
                                    onClick={() => removeButton(onImageRemove, cardIndex, index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </ImageUploading>
                </div>
              ))}
            </div>
            <div className='flex justify-center p-4'>
              <button
                onClick={handleSubmitAllImages}
                className={`text-white bg-black px-4 py-2 rounded-lg  ${loading ? 'cursor-not-allowed' : ''}`}
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Submit All Images'}
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Success"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white rounded-lg p-8">
          <h2 className="text-2xl font-bold mb-4">Success</h2>
          <p className="mb-4">Images uploaded successfully!</p>
          <button onClick={() => setModalIsOpen(false)} className="px-4 py-2 bg-black text-white rounded-lg">
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}
