import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { useAuth } from '../api/AuthContext';
import register from '../api/auth';
import LottieAnimation from './LottieAnimation';
import animationData from '../login.json';


const Register = () => {
    const { user, loading } = useAuth();
    const navigate = useNavigate();
    const [inputData, setInputData] = useState({
        name: '',
        email: '',
        password: '',
        confirm_password: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (!loading && user) {
            navigate('/chat', { replace: true });
        }
    }, [user, loading, navigate]);

    const validateForm = () => {
        const schema = Yup.object().shape({
            name: Yup.string().required('Name required.').min(2, 'Name must be 2 characters or more.'),
            email: Yup.string().email('Invalid email address.').required('Email required.'),
            password: Yup.string().required('Password required.').min(6, 'Password must be 6 characters or more.'),
            confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match.')
        });

        return schema.validate(inputData, { abortEarly: false })
            .then(() => {
                setErrors({});
                return true;
            })
            .catch((err) => {
                const validationErrors = {};
                err.inner.forEach((error) => {
                    validationErrors[error.path] = error.message;
                });
                setErrors(validationErrors);
                return false;
            });
    };

    const handleData = (e) => {
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
    };

    const sendForm = async (e) => {
        e.preventDefault();
        const isValid = await validateForm();
        if (isValid) {
            try {
                const response = await register(inputData);
                localStorage.setItem("tk", JSON.stringify(response.data.data.token));
                toast.success(response.data.message);
                navigate('/chat', { replace: true });
            } catch (error) {
                toast.error(error.message || 'Something went wrong!');
            }
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <ClipLoader color="#4A90E2" loading={loading} size={60} />
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-bg-white p-4">
            <div className="flex flex-col md:flex-row w-full max-w-4xl bg-gray-900 rounded-lg shadow-md overflow-hidden">
                <div className="hidden md:flex md:w-1/2 p-8 bg-black items-center justify-center">
                    <div>
                        <LottieAnimation lottie={animationData} />
                    </div>
                </div>
                <div className="w-full md:w-1/2 p-8 flex flex-col justify-center bg-white text-black">
                    <img
                        src="/logo.png"
                        alt="Register illustration"
                        className="mb-4 h-32 w-32 mx-auto"
                    />
                    <p className="text-black mb-4 text-center md:text-left">
                        Please create your account and start the adventure
                    </p>
                    <form onSubmit={sendForm} className="space-y-4">
                        <div>
                            <label className="block text-black">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={inputData.name}
                                onChange={handleData}
                                className="mt-1 block w-full px-4 py-2 border border-gray-600 rounded-md shadow-sm bg-white text-black focus:outline-none focus:ring focus:ring-opacity-50"
                            />
                            {errors.name && <div className="text-red-500">{errors.name}</div>}
                        </div>
                        <div>
                            <label className="block text-black">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={inputData.email}
                                onChange={handleData}
                                className="mt-1 block w-full px-4 py-2 border border-gray-600 rounded-md shadow-sm bg-white text-black focus:outline-none focus:ring focus:ring-opacity-50"
                            />
                            {errors.email && <div className="text-red-500">{errors.email}</div>}
                        </div>
                        <div>
                            <label className="block text-black">Password</label>
                            <input
                                type="password"
                                name="password"
                                value={inputData.password}
                                onChange={handleData}
                                className="mt-1 block w-full px-4 py-2 border border-gray-600 rounded-md shadow-sm bg-white text-black focus:outline-none focus:ring focus:ring-opacity-50"
                            />
                            {errors.password && <div className="text-red-500">{errors.password}</div>}
                        </div>
                        <div>
                            <label className="block text-black">Confirm Password</label>
                            <input
                                type="password"
                                name="confirm_password"
                                value={inputData.confirm_password}
                                onChange={handleData}
                                className="mt-1 block w-full px-4 py-2 border border-gray-600 rounded-md shadow-sm bg-white text-black focus:outline-none focus:ring focus:ring-opacity-50"
                            />
                            {errors.confirm_password && <div className="text-red-500">{errors.confirm_password}</div>}
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-black text-white py-2 rounded-md shadow-md  focus:outline-none focus:ring "
                        >
                            Sign Up
                        </button>
                    </form>
                    <div className="mt-6 text-center">
                        <p className="text-gray-800">
                            Already have an account?{' '}
                            <a href="/login" className="text-black hover:underline">
                                Log In
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
