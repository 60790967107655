import axios from "axios"

const axiosApi = axios.create({
    // for production
    baseURL: "https://onetapaisolutions.com/voiceai/public",

    // for developement
    // baseURL: "http://localhost:7000/api/v1"
})

axiosApi.interceptors.request.use(async (config) => {
    let token = null
    try {
        token = localStorage.getItem("tk");
        // token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL29uZXRhcGFpc29sdXRpb25zLmNvbS92b2ljZWFpL3B1YmxpYy9hcGkvbG9naW4iLCJpYXQiOjE3MjA4NzMwMjQsImV4cCI6MTcyMDg3NjYyNCwibmJmIjoxNzIwODczMDI0LCJqdGkiOiJPbG55Y2VBYlJGeHJRSkthIiwic3ViIjoiMTAiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.HkOYVPkM6k4TWKuC0aA-yvTxv20oof-toVcpZUaZXYE";

    } catch (err) {
        token = null;
    }

    if (token) {
        console.log("token frrrr", token);
        config.headers["Authorization"] = `Bearer ${(token)}`;
    }

    return config;
})


// Check if login is required
axiosApi.interceptors.response.use(
    (response) => {
        // Handle a successful response
        return response;
    },
    (error) => {
        // Handle an error response
        if (error.response.status === 401) {
            localStorage.removeItem("tk")
            if (window.location.href.includes("/admin") || window.location.href.includes("/account")) {
                return window.location.href = `/login?to=${window.location.pathname}`
            }
        }
        return Promise.reject(error);
    }
);

export default axiosApi