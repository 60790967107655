import axiosApi from "../api/axiosApi.js";

export async function login(email, password, confirm_password) {
    const req = await axiosApi.post('/api/login', { email, password, confirm_password });
    return req.data;
}

export async function logout() {
    const req = await axiosApi.post('/api/logout');
    localStorage.removeItem("tk");
    return req.data;
}

export default function register(user) {
    return axiosApi.post("/api/register", user);
}

export async function currentUser() {
    // ${localStorage.getItem("tk")}

    const req = await axiosApi.get("/api/me", {

    });

    if (req.data.error) {
        throw new Error(req.data.error);
    }





    return req.data;
}

export async function sendPasswordResetEmail(email) {
    const req = await axiosApi.post("/auth/forget-password", { email });
    return req.data;
}

export async function sendResetPassword(payload) {
    const req = await axiosApi.post("/auth/reset-password", payload);
    return req.data;
}
