import React, { useState } from 'react';
import Side from './Sidebar';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import axios from 'axios';
import { useAuth } from '../api/AuthContext';
import Modal from 'react-modal';

const Voice = () => {
  const recorderControls = useAudioRecorder();
  const [audioBlob, setAudioBlob] = useState(null);
  const [clonedVoiceUrl, setClonedVoiceUrl] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { user } = useAuth();

  const addAudioElement = (blob) => {
    setAudioBlob(blob);
  };

  const reRecord = () => {
    setAudioBlob(null);
    recorderControls.startRecording();
  };

  const cloneVoice = async () => {
    const formData = new FormData();
    formData.append('voice[]', audioBlob, 'audio.webm');

    try {
      const response = await axios.post('https://onetapaisolutions.com/voiceai/public/api/submitVoiceResponse', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('tk'),
        },
      });
      console.log(response.data);
      if (!response.data.error) {
        setModalIsOpen(true);
      }
    } catch (error) {
      console.error('Error uploading audio:', error);
    }
  };

  const getAudioURL = (blob) => {
    return URL.createObjectURL(blob);
  };

  return (
    <div className='bg-white w-screen h-screen flex flex-col md:flex-row overflow-x-hidden'>
      <div className='fixed'><Side /></div>
      <div className='flex flex-col w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto mt-16 md:mt-4 px-4 md:px-8'>
        <h1 className='text-black text-2xl md:text-4xl font-bold text-center mb-4'>Voice Cloning</h1>
        <p className='text-black text-center mb-8'>
          Please read the paragraphs clearly and loudly in a quiet environment for the best results.
        </p>
        <div className='bg-black p-4 md:p-6 rounded-lg shadow-lg'>
          <p className='text-white text-lg md:text-2xl font-bold mb-2'>Audio Clip: Reflective and Narrative</p>
          <p className='text-white text-sm md:text-base mb-4'>
            "Late one evening, as I wandered through the bustling city streets, I couldn't help but reflect on the vibrant life around me. The city, with its towering skyscrapers and the constant hum of activity, always had a way of energizing my spirit. I remember vividly, the buzz of conversations blending with the distant sound of a saxophone from a nearby jazz club. The aroma of freshly brewed coffee wafting from corner cafes and the colorful lights illuminating the night sky added to the magic. It's places like these that remind us of the endless possibilities and adventures that await. As I navigate these familiar avenues, I am reminded of the beauty in our everyday experiences and the joy of exploring the world around us."
          </p>
          {audioBlob ? (
            <div className='flex flex-col items-center'>
              <p className='text-green-500 font-bold mb-2'>Recording Completed</p>
              <audio className='mb-4' src={getAudioURL(audioBlob)} controls />
              <button
                className='bg-white text-black py-2 px-4 rounded cursor-pointer hover:bg-yellow-700'
                onClick={reRecord}
              >
                Re-record Audio Clip
              </button>
            </div>
          ) : (
            <div className='flex flex-col items-center'>
              <AudioRecorder
                onRecordingComplete={addAudioElement}
                recorderControls={recorderControls}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                downloadOnSavePress={false}
                downloadFileExtension="webm"
              />
              <button
                className='mt-4 bg-white text-black py-2 px-4 rounded cursor-pointer '
                onClick={recorderControls.startRecording}
              >
                Record Audio Clip
              </button>
            </div>
          )}
        </div>
        <button
          className='bg-black text-white py-2 px-4 rounded mt-4 cursor-pointer  disabled:opacity-50 mx-auto'
          onClick={cloneVoice}
          disabled={!audioBlob}
        >
          Submit
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="Submission Success"
          className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center p-4"
          overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75"
        >
          <div className="bg-white p-8 rounded shadow-lg text-center">
            <h2 className="text-2xl font-bold mb-4">Submission Successful!</h2>
            <p className="mb-4">Your voice response has been submitted successfully.</p>
            <button
              className="bg-black text-white py-2 px-4 rounded mt-2 cursor-pointer"
              onClick={() => setModalIsOpen(false)}
            >
              Close
            </button>
          </div>
        </Modal>
        {clonedVoiceUrl && (
          <div className='fixed bottom-10 left-1/2 transform -translate-x-1/2 bg-gray-900 p-4 rounded shadow-lg'>
            <p className='text-gray-300 text-lg'>Cloned Voice:</p>
            <audio src={clonedVoiceUrl} controls />
          </div>
        )}
      </div>
    </div>
  );
};

export default Voice;
