import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../api/AuthContext';
import { ChevronRightIcon, ChevronDownIcon, ChatIcon, SpeakerphoneIcon, VideoCameraIcon, DocumentTextIcon, XIcon, MenuIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import { Menu } from '@headlessui/react';


// SidebarItem Component
const SidebarItem = ({ icon, label, onClick, path, subItems }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (path) {
            setIsActive(location.pathname === path);
        } else if (subItems) {
            setIsActive(subItems.some(subItem => location.pathname === subItem.path));
            setIsExpanded(subItems.some(subItem => location.pathname === subItem.path));
        }
    }, [location.pathname, path, subItems]);

    const handleClick = () => {
        if (subItems) {
            setIsExpanded(!isExpanded);
        } else if (path) {
            navigate(path);
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <div>
            <div
                className={`my-2 cursor-pointer px-4 py-2 flex items-center justify-between rounded-lg ${isActive ? 'bg-white text-black' : 'text-gray-300 hover:text-white hover:bg-gray-700'}`}
                onClick={handleClick}
            >
                <div className="flex items-center space-x-2">
                    {icon}
                    <span>{label}</span>
                </div>
                {subItems && (
                    isExpanded ? (
                        <ChevronDownIcon className="h-5 w-5 text-gray-300" />
                    ) : (
                        <ChevronRightIcon className="h-5 w-5 text-gray-300" />
                    )
                )}
            </div>
            {isExpanded && subItems && (
                <div className="ml-8">
                    {subItems.map((subItem, index) => (
                        <div
                            key={index}
                            className={`my-2 cursor-pointer px-4 py-2 flex items-center justify-between rounded-lg ${location.pathname === subItem.path ? 'bg-purple-500 text-white' : 'text-gray-300 hover:text-white hover:bg-gray-700'}`}
                            onClick={() => navigate(subItem.path)}
                        >
                            <span>{subItem.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const Side = ({ setCurrentView }) => {
    const navigate = useNavigate();
    const [barOpen, setBarOpen] = useState(false);
    const { user, logout } = useAuth();

    return (
        <div className="transition-transform">
            {barOpen ? (
                <div className="w-64 h-screen bg-black text-white p-4 transition-all flex flex-col justify-between">
                    <div>
                        <div className="flex items-center justify-center mb-8 cursor-pointer">
                            <img src="/logo.png" alt="Logo" className="h-10 w-auto bg-white rounded-full border-white" />
                            <button className="ml-20" onClick={() => setBarOpen(false)}>
                                <XIcon className="w-8 h-8 text-white" />
                            </button>
                        </div>
                        <SidebarItem
                            icon={<ChatIcon className="h-5 w-5" />}
                            label="Chat"
                            path="/chat"
                            onClick={() => {
                                setCurrentView('default');
                                navigate('/chat');
                            }}
                        />
                        <SidebarItem
                            icon={<SpeakerphoneIcon className="h-5 w-5" />}
                            label="Voice"
                            path="/voice"
                            onClick={() => {
                                setCurrentView('subscription');
                                navigate('/voice');
                            }}
                        />
                        <SidebarItem
                            icon={<VideoCameraIcon className="h-5 w-5" />}
                            label="Image"
                            path="/ImageUploader"
                            onClick={() => {
                                setCurrentView('transactions');
                                navigate('/ImageUploader');
                            }}
                        />
                        <SidebarItem
                            icon={<DocumentTextIcon className="h-5 w-5" />}
                            label="Final Steps"
                            path="/finalStep"
                            onClick={() => {
                                setCurrentView('finalStep');
                                navigate('/finalStep');
                            }}
                        />
                        {/* <SidebarItem
                            icon={<DocumentTextIcon className="h-5 w-5" />}
                            label="Talk to AI"
                            path="/userInterface"
                            onClick={() => {
                                setCurrentView('userInterface');
                                navigate('/userInterface');
                            }}
                        /> */}
                    </div>
                    <div className="flex items-center justify-between mt-4 p-4 bg-black rounded-lg">
                        <div className="flex items-center space-x-4">
                            <img src="https://ideogram.ai/assets/progressive-image/balanced/response/Ns6zJf-HSDmhV3-PgXL6UA" alt="Profile" className="h-10 w-10 rounded-full" />
                            <div>
                                <div className="text-white font-bold">{user.name}</div>
                                <div className="text-gray-400 text-xs">{user.email}</div>
                            </div>
                        </div>
                        <Menu as="div" className="relative -mt-6">
                            <Menu.Button className="flex items-center">
                                <DotsVerticalIcon className="h-6 w-6 text-gray-300" />
                            </Menu.Button>
                            <Menu.Items className="absolute right-0 mt-2 w-48 bg-white divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none">
                                <div className="py-1 ">

                                    <Menu>
                                        {({ active }) => (
                                            <button
                                                className={`${active ? 'bg-gray-100' : ''
                                                    } flex justify-between w-full px-4 py-2 text-sm text-gray-700`}
                                                onClick={() => {
                                                    logout();

                                                }}
                                            >
                                                Log Out
                                            </button>
                                        )}
                                    </Menu>
                                </div>
                            </Menu.Items>
                        </Menu>
                    </div>
                </div>
            ) : (
                <div className="ml-5 mt-5">
                    <button onClick={() => setBarOpen(true)}>
                        <MenuIcon className="h-10 w-10 text-black" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default Side;
